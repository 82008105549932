import { get, post } from './axios'

export default {
  // 查询合同详情
  getContractDetailInfo(param) {
    return post('/core/saasApi/getContractDetailInfo', param)
  },
  // 获取印章/签名列表
  getSignList(param) {
    return post('/core/saasApi/getSignList', param)
  },
  // 添加印章
  addContract(param) {
    return post('/core/saasApi/addContract', param)
  },
  addNewContract(param) {
    return post('/core/saasApi/addNewContract', param)
  },
  // 获取短信验证码
  getTelCode(param) {
    return post('/core/saasApi/getTelCode', param)
  },
  // 上传签名
  addNewSeal(param) {
    return post('/core/saasApi/addNewSeal', param)
  },
  // 预览
  getContractImages(param) {
    return post('/core/saasApi/getContractImages', param)
  },
  // 预览
  signConfig(param) {
    return get('/user/wechat/signConfig', param)
  },
}
